
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { DataItemProps } from 'vuetify'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'
  import { ResourceHeaders } from '@/views/datatables/resources/roles'
  import { Entity } from '@/entities'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import dayjs from 'dayjs'
  import { fixDate } from '@/utils/general'

interface RowData extends DataItemProps {
  item: Entity
}

  const sortedData = {
    '@': 'id',
    client: 'name',
    pipeline: 'description',
    saleStatus: 'price',
    purchase: 'auto.name',
    'sale.auto': 'name',
    'deal.auto': 'name',
    'purchase.appraisal': 'status.description',
    'sale.order.financing.evaluation': 'status.description',
    agreement: 'value',
    'appraisal.deal.auto': 'name',
    'appraisal.deal.lead.client': 'name',
    appraisalStatus: 'status.description',
    inspectionStatus: 'status.description',
    auto: 'name',
    appraisalPrice: 'value',
    inspectionCost: 'value',
    negotiationAmount: 'value',
    'negotiation.negotiationAmount': 'value',
    'negotiation.auto': 'name',
    'negotiation.client': 'name',
    price: 'value',
    'saleOrder.deal.auto': 'name',
    'saleOrder.deal.auto.autoStatus': 'price',
    startingAmount: 'value',
    evaluation: 'status.description',
    'sale.auto.autoStatus': 'status.description',
    discount: 'value',
    alerts: 'activityAlert.order',
    lastLeadActivityOutDate: '@',
  }

@Component({
  components: {
    ActionCell: () => import('./cell/ActionCell.vue'),
    ExpandedRow: () => import('./ExpandedRow.vue'),
    TextCell: () => import('./cell/TextCell.vue'),
    DateCell: () => import('./cell/DateCell.vue'),
    StatusCell: () => import('./cell/StatusCell.vue'),
    LinkedCell: () => import('./cell/LinkedCell.vue'),
    ExpandCell: () => import('./cell/ExpandCell.vue'),
    PriceCell: () => import('./cell/PriceCell.vue'),
    IconCell: () => import('./cell/IconCell.vue'),
    StockCell: () => import('./cell/StockCell.vue'),
    ProgressCell: () => import('./cell/ProgressCell.vue'),
    AddressCell: () => import('./cell/AddressCell.vue'),
    RatingCell: () => import('./cell/RatingCell.vue'),
    AlertCell: () => import('./cell/AlertCell.vue'),
    ListComponentCell: () => import('./cell/ListComponentCell.vue'),
    InspectionSummaryCell: () => import('./cell/InspectionSummaryCell.vue'),
    PhotoCell: () => import('./cell/PhotoCell.vue'),
    LetterApprovalCell: () => import('./cell/LetterApprovalCell.vue'),
    BackupPaymentCell: () => import('./cell/BackupPaymentCell.vue'),
    ChipsCell: () => import('./cell/ChipCell.vue'),
    StockDetailCell: () => import('./cell/stockDetailCell.vue'),
    PdfReserveCell: () => import('./cell/reservePDFCell.vue'),
    AlertReserveSellCell: () => import('./cell/AlertReserveSell.vue'),
    PdfSaleCell: () => import('./cell/salePDFCell.vue'),
    PdfResponsabilityCell: () => import('./cell/responsabilityPdfCell.vue'),
    PdfPurchaseCell: () => import('./cell/purchasePDFCell.vue'),
    PdfWarrantyCell: () => import('./cell/warrantyPDFCell.vue'),
    PdfConsignmentCell: () => import('./cell/consignmentPDFCell.vue'),
    PdfConsignmentWithdrawalCell: () => import('./cell/consignmentWithdrawalPDFCell.vue'),
    ConsignmentCell: () => import('./cell/ConsignmentCell.vue'),
    ConsignmentExpirationCell: () => import('./cell/ConsignmentExpirationCell.vue'),
    StockButtonCell: () => import('./cell/StockButtonCell.vue'),
    DailyAccountAlertCell: () => import('./cell/DailyAccountAlert.vue'),
    StockDailyCell: () => import('./cell/StockDaily.vue'),
    StockLeadReceivedCell: () => import('./cell/StockLeadReceived.vue'),
    AlertConsignmentCell: () => import('./cell/AlertConsignment.vue'),
    PurchaseStatusCell: () => import('./cell/PurchaseStatusCell.vue'),
  },
  methods: {
    ...mapActions('resources/form', ['fetchData']),
    ...mapMutations('app', ['setProcess']),
  },
  computed: { ...mapGetters('app', ['isMobile']) },
})
  export default class BaseTable extends Vue {
  @Prop({ type: Boolean }) readonly loading!: boolean;
  @Prop({ type: Boolean, default: true }) readonly single!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly headers!: ResourceHeaders;
  @Prop({ type: Array, default: () => [] }) readonly items!: [];
  @Prop({ type: String, default: '' }) readonly slug!: string;
  @Prop({ type: String, default: '' }) readonly model!: string;
  @Prop({ type: Number, default: 10 }) readonly itemsPerPage!: number;
  @Prop({ type: Number, default: 1 }) readonly page!: boolean;

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  setProcess!: (payload: any) => void

  isMobile!: boolean
  change = false

  cells ({ item, expand, isExpanded }: RowData) {
    const { headers } = this

    const disabled = item.isClosed
    return headers.map(({ type: name, value: path, options, align }, i) => ({
      key: i,
      name,
      align,
      props: {
        item: getObjectValueByPath(item, path),
        options,
        expand,
        isExpanded,
        disabled,
      },
    }))
  }

  customSort (items, sortBy, sortDesc) {
    const path = sortBy && sortBy[0]

    if (!path?.length) return items

    items.sort((a, b) => this.compareItems(a, b, path))

    if (path === 'alerts') return items
    if (!sortDesc[0]) items.reverse()

    return items
  }

  compareItems (a, b, path) {
    if (!sortedData[path]) return []

    const [itemA, itemB] = this.getItemComparisonValues(a, b, path)

    return this.compareValues(itemA, itemB)
  }

  getItemComparisonValues (a, b, path) {
    let itemA, itemB

    if (path === '@') {
      itemA = Number(String(a))
      itemB = Number(String(b))
    } else if (sortedData[path].includes('.') || path.includes('.')) {
      const dataA = getObjectValueByPath(a, path)
      const dataB = getObjectValueByPath(b, path)
      if (path !== 'alerts') {
        itemA = getObjectValueByPath(dataA, sortedData[path])
        itemB = getObjectValueByPath(dataB, sortedData[path])
      } else if (dataA?.length && dataB?.length) {
        itemA = dataA[0].order
        itemB = dataB[0].order
      }
    } else {
      itemA = getObjectValueByPath(a, path) ? getObjectValueByPath(a, path)[sortedData[path]] : undefined
      itemB = getObjectValueByPath(b, path) ? getObjectValueByPath(b, path)[sortedData[path]] : undefined
    }

    if (!itemA && !itemB) {
      const valueA = getObjectValueByPath(a, path)
      const valueB = getObjectValueByPath(b, path)
      const dateFormat = 'DD/MM/YY HH:mm'

      if (dayjs(fixDate(valueA), dateFormat, true).isValid() || dayjs(fixDate(valueB), dateFormat, true).isValid()) {
        itemA = valueA?.length ? dayjs(fixDate(valueA), dateFormat) : undefined
        itemB = valueB?.length ? dayjs(fixDate(valueB), dateFormat) : undefined
      }
    }

    return [itemA, itemB]
  }

  compareValues (itemA, itemB) {
    if (itemA === undefined && itemB === undefined) return 0

    // Si uno de los dos es undefined, el undefined va al final
    if (itemA === undefined) return 1
    if (itemB === undefined) return -1

    // Comparar instancias de Dayjs si ambos son fechas
    if (itemA instanceof dayjs || itemB instanceof dayjs) {
      if (itemA?.isAfter(itemB)) return -1
      if (itemA?.isBefore(itemB)) return 1
      return 0 // Si son iguales
    }

    // Comparar los valores normalmente
    if (itemA < itemB) return -1
    if (itemA > itemB) return 1

    return 0
  }

  get sortBy () {
    const { slug } = this

    return slug === 'staff_leads' ? 'lastLeadActivityOutDate' : ''
  }

  transformString (input) {
    if (!input) return input

    const hasSecondUppercase = /[A-Z].*[A-Z]/.test(input)

    if (hasSecondUppercase) {
      return input.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
    } else {
      return input.toLowerCase()
    }
  }

  @Watch('model', { deep: true, immediate: true })
  async onModelChange (val) {
    if (!val) return
    const processName = this.transformString(val)

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: processName } },
      force: true,
    })

    this.setProcess(process[0])
  }
  }
